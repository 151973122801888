<template>
  <div>
    <div
      class="modal"
      id="modalTerminos"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Términos y condiciones de uso.</h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="1"
                      v-model="terminos"
                      id="terminosCondiciones"
                    />
                    <label class="form-check-label" for="terminosCondiciones">
                      Acepto 
                      <span @click="politica(1)">términos y condiciones.</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div>
                  Requerimos la autorización para el tratamiento de tus datos
                  personales incluyendo datos sensibles, la cual es facultativa,
                  conforme a lo dispuesto en nuestra
                  <span @click="politica(2)"
                    >Política de tratamiento de datos personales.</span
                  >
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value="2"
                    v-model="privacidad"
                    id="privacidad"
                  />
                  <label class="form-check-label" for="privacidad">
                    He leído y acepto el
                    <span @click="politica(3)">aviso de privacidad.</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" :disabled="!disabled" @click="continuar" class="btn btn-primary">
              Acepto
            </button>
            <button type="button" class="btn btnSecondary" @click="cancelar">
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {analytics} from "../../../firebase";
export default {
  name: "ModalTerms",
  mounted () {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  data() {
    return {
      terminos: null,
      privacidad: null,
    };
  },
  methods: {
    politica(tipo) {
      window.$("#modalTerminos").modal("hide");
      let name = "";
      switch (tipo) {
        case 1:
          name = "terminos";
          break;
        case 2:
          name = "datosPersonales";
          break;
        case 3:
          name = "privacidad";
          break;
      }

      this.$router.push({ name });
    },
    cancelar() {
      window.$("#modalTerminos").modal("hide");
      this.$router.push({ name: "inicio" });
    },
    continuar(){
      analytics.logEvent("Terminos-Formatos-Aceptados");
      window.$("#modalTerminos").modal("hide");
    }
  },
  computed: {
    disabled() {
      return this.terminos && this.privacidad;
    },
  },
};
</script>
<style scoped>
.modal-body {
  display: block !important;
}
span {
  color: #3383c8;
  cursor: pointer;
}
.btnSecondary {
  background: lightgray !important;
  color: black !important;
}
</style>